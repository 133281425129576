import '../scss/style.scss';

document.addEventListener('DOMContentLoaded', async () => {
  const namespaceAttribute = 'data-namespace';
  const namespaceSelector = `[${namespaceAttribute}]`;

  const namespaceElement = document.querySelector(namespaceSelector);
  const namespaceAttributeValue = namespaceElement.getAttribute(namespaceAttribute);
  const capitalizedNamespaceAttributeValue = namespaceAttributeValue.charAt(0).toUpperCase() + namespaceAttributeValue.slice(1);

  const { [capitalizedNamespaceAttributeValue]: NamespaceClass } = await import('./namespaces');

  window.namespace = new NamespaceClass();
  window.namespace.init();
});
